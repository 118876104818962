import React from "react"
import styled from "@emotion/styled"

import Card from "./Card"
import Cta from "./Cta"

const Container = styled.div`
  padding: 3rem 1.5rem;
  margin: ${({ reviewsOnly }) => (reviewsOnly ? "0 auto" : "6rem auto")};
  max-width: 130rem;
`

const GridContainer = styled.div`
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  grid-gap: 1.5rem;
  padding: ${({ reviewsOnly }) => (reviewsOnly ? "0 3rem" : "3rem 3rem 9rem")};
  @media only screen and (max-width: 32.5em) {
    grid-template-columns: 1fr;
    padding: 3rem 0;
  }
`

const Primary = styled.h2`
  text-align: center;
  font-size: 3.5rem;
  font-weight: 700;
  text-transform: uppercase;
  line-height: 1.3;
  color: #2196f3;
  background: linear-gradient(to right, #2196f3, #2dc7ff);
  -webkit-background-clip: text;
  background-clip: text;
  -webkit-text-fill-color: transparent;
  text-fill-color: transparent;
`

const Subtitle = styled.h3`
  font-size: 1.9rem;
  font-weight: 400;
  text-align: center;
  padding: 1.5rem 10rem;
  @media only screen and (max-width: 52em) {
    padding: 1.5rem 0;
  }
`

const Content = styled.a`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  height: 100%;
  text-decoration: none;
`

const Author = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
`

const Body = styled.p`
  color: #505050;
  padding-bottom: 1rem;
`

const Name = styled.span`
  color: #000;
`

const Section = styled.span`
  display: flex;
  align-items: center;
`

const Stars = styled.span`
  span {
    &::after {
      content: "\u2605";
      color: #2196f3;
      font-size: 2rem;
    }
  }
`

const Review = ({ reviews = [], reviewsOnly = false }) => {
  return (
    <Container reviewsOnly={reviewsOnly}>
      {!reviewsOnly && (
        <>
          <Primary>Customer Reviews</Primary>
          <Subtitle>Check out what people are saying about us!</Subtitle>
        </>
      )}
      <GridContainer reviewsOnly={reviewsOnly}>
        {reviews &&
          reviews.map(({ body, author, url }, count) => (
            <Card key={count}>
              <a href={url} style={{ textDecoration: "none" }}>
                <Content>
                  <Body>{body}</Body>
                  <Author>
                    <Section>
                      <Stars>
                        <span /> <span /> <span /> <span /> <span />
                      </Stars>
                    </Section>
                    <Name>{author}</Name>
                  </Author>
                </Content>
              </a>
            </Card>
          ))}
      </GridContainer>
      {!reviewsOnly && (
        <Cta href="https://app.skipthedepot.com">Get started</Cta>
      )}
    </Container>
  )
}

export default Review
