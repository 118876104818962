import React from "react"
import styled from "@emotion/styled"
import Button from "./Button"

const Container = styled.div`
  text-align: center;
  margin: 20px 0;
`

const InternalLink = styled.a`
  color: #fff;
  text-decoration: none;
  position: relative;
  padding-bottom: 0.3rem;
  white-space: nowrap;
  &::after {
    position: absolute;
    content: "";
    height: 1px;
    width: 100%;
    background-color: #fff;
    bottom: 0;
    left: 0;
  }
  &:hover {
    &::after {
      height: 2px;
    }
  }
`
const CtaScroll = ({ href, children }) => (
  <Container>
    <InternalLink href={href}>{children}</InternalLink>
  </Container>
)

export default CtaScroll
