import React, { useState, useEffect } from "react"
import styled from "@emotion/styled"

import Card from "./Card"
import Cta from "./Cta"
import Loading from "./Loading"

const Container = styled.div`
  padding: 3rem;
  max-width: 130rem;
  margin: 0 auto;
`

const Primary = styled.h2`
  text-align: center;
  font-size: 3.5rem;
  font-weight: 700;
  text-transform: uppercase;
  line-height: 1.3;
  padding: 3rem 0 1rem;
  color: #2196f3;
  background: linear-gradient(to right, #2196f3, #2dc7ff);
  -webkit-background-clip: text;
  background-clip: text;
  -webkit-text-fill-color: transparent;
  text-fill-color: transparent;
`

const Secondary = styled.h4`
  font-size: 2.5rem;
  font-weight: 400;
  text-align: center;
  color: #505050;
`

const Content = styled.div`
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  text-align: center;
  padding: 3rem;
  .card {
    margin: 1.5rem;
    padding: 3rem 0;
    width: 30%;
    min-width: 36rem;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    @media only screen and (max-width: 40em) {
      width: 100%;
      min-width: 0;
    }
  }
  @media only screen and (max-width: 32.5em) {
    padding: 3rem 0;
  }
`

const Details = styled.div`
  padding: 3rem;
`

const Image = styled.img`
  width: 90%;
  height: 13rem;
  object-fit: contain;
`

// const Goal = styled.span`
//   text-align: right;
//   display: block;
//   padding-right: 5%;
// `
//
// const LoadingBar = styled.div`
//   margin: 0 auto;
//   height: 3rem;
//   border-radius: 50px;
//   padding: 0.4rem;
//   width: 90%;
//   border: 1px solid #909090;
//   overflow: hidden;
//   span {
//     display: block;
//     border-radius: 50px;
//     height: 100%;
//     background: linear-gradient(to right, #2dc7ff, #1976d2);
//   }
// `

const FundraisingShort = ({ data: { title, loc } }) => {
  const [data, setData] = useState([])

  function toCamelCase(string) {
    string = string
      .toLowerCase()
      .replace(/(?:(^.)|([-_\s]+.))/g, function (match) {
        return match.charAt(match.length - 1).toUpperCase()
      })
    return string.charAt(0).toLowerCase() + string.substring(1)
  }

  useEffect(() => {
    setData([])
    getCharities()
  }, [])

  async function getCharities() {
    try {
      const res = await fetch(
        "https://app.skipthedepot.com/api/getFeaturedCharities",
        // "http://localhost:3001/getFeaturedCharities",
        {
          method: "GET",
          headers: {
            Accept: "application/json",
            "Content-Type": "application/json",
          },
        }
      )
      const json = await res.json()

      const cityCharites = []
      const areaCharities = [] // Fallback if city has no featured charities

      json.charities.map((charity) => {
        const regionsFeaturedIn = charity.regionsFeaturedIn.map(({ name }) =>
          toCamelCase(name)
        )

        if (regionsFeaturedIn.includes(loc.city)) {
          cityCharites.push(charity)
        }

        if (regionsFeaturedIn.includes(loc.area)) {
          areaCharities.push(charity)
        }
      })

      setData(cityCharites.length > 0 ? cityCharites : areaCharities)
    } catch (error) {
      console.log(error)
    }
  }

  function renderData(data) {
    return data.map(({ name, url, logo, mission, goal, current }, count) => (
      <Card key={count}>
        <Details>
          <Image src={logo} />
          <Secondary>{name}</Secondary>
          {/* <Goal>${goal}</Goal>
              <LoadingBar>
                <span style={{ width: `${(current / goal) * 100}%` }} />
              </LoadingBar> */}
        </Details>
        <Cta href={`https://app.skipTheDepot.com/${url}`} variant>
          Donate now
        </Cta>
      </Card>
    ))
  }

  return (
    <Container>
      <Primary>{title}</Primary>
      <Content>
        {data && data.length > 0 ? renderData(data) : <Loading size="5" />}
      </Content>
    </Container>
  )
}

export default FundraisingShort
