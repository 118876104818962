import React from "react"
import styled from "@emotion/styled"
import Cta from "./Cta"
import CtaScroll from "./CtaScroll"
import { createHTML } from "../utils"

const Container = styled.section`
  position: relative;
  overflow: hidden;
  background: url(/img/svg/pattern.svg) center center/15%,
    linear-gradient(to right, #2c5364, #203a43, #0f2027);
  padding: 10rem 3rem 20rem;
  @media only screen and (max-width: 56.25em) {
    background: url(/img/svg/pattern.svg) center center/25%,
      linear-gradient(to right, #2c5364, #203a43, #0f2027);
  }
  @media only screen and (max-width: 52em) {
    padding: 3rem 1.5rem;
  }
  @media only screen and (max-width: 40em) {
    background: url(/img/svg/pattern.svg) center center/45%,
      linear-gradient(to right, #2c5364, #203a43, #0f2027);
  }
  @media only screen and (max-width: 32.5em) {
    background: url(/img/svg/pattern.svg) center center/65%,
      linear-gradient(to right, #2c5364, #203a43, #0f2027);
  }
`

const Svg = styled.svg`
  width: 101%;
  position: absolute;
  bottom: -1px;
  left: 0;
  transform: rotate(180deg);
`

const Branding = styled.div`
  color: #fff;
  z-index: 2;
  text-align: center;
  max-width: 130rem;
  margin: 3rem auto 9rem;
  padding: 5rem 1.5rem;
`

const Primary = styled.h1`
  font-size: 5.5rem;
  line-height: 1.1;
  padding: 1rem 0;
  @media only screen and (max-width: 32.5em) {
    font-size: 4rem;
  }
  span {
    display: block;
    font-weight: 400;
    font-size: 2rem;
  }
`

const Secondary = styled.h2`
  font-size: 2.5rem;
  font-weight: 400;
  padding: 2rem 0;

  > p {
      padding-bottom: 2rem;
  }

  @media only screen and (max-width: 32.5em) {
    font-size: 2rem;
  }
`

const PromoCodeText = styled.div`
  font-weight: 600;
  line-height: 1.1;
`

const PromoCode = styled.h3`
  margin: 1rem 0 2rem;
  font-size: 4rem;
  border: solid #fff 4px;
  display: inline-block;
  padding: 1rem 5rem;
  background: none;
  color: #fff;
c  border-radius: 5px;
  @media only screen and (max-width: 32.5em) {
    font-size: 2.5rem;
    padding: 1rem 3rem;
  }
`

const Splash = ({ title, subtitles, promoCode, promoCodeText, ctaScroll }) => {
  const copyToClipboard = (text) => () => {
    const textArea = document.createElement("textarea")
    textArea.value = text

    // Avoid scrolling to bottom
    textArea.style.top = "0"
    textArea.style.left = "0"
    textArea.style.position = "fixed"

    document.body.appendChild(textArea)
    textArea.focus()
    textArea.select()
    textArea.setSelectionRange(0, 99999) /*For mobile devices*/

    document.execCommand("copy")

    document.body.removeChild(textArea)
  }

  return (
    <>
      <Container>
        <Svg viewBox="0 0 1200 120">
          <path
            d="M0 0v46.29c47.79 22.2 103.59 32.17 158 28 70.36-5.37 136.33-33.31 206.8-37.5 73.84-4.36 147.54 16.88 218.2 35.26 69.27 18 138.3 24.88 209.4 13.08 36.15-6 69.85-17.84 104.45-29.34C989.49 25 1113-14.29 1200 52.47V0z"
            opacity=".25"
            fill="#fff"
          ></path>
          <path
            d="M0 0v15.81c13 21.11 27.64 41.05 47.69 56.24C99.41 111.27 165 111 224.58 91.58c31.15-10.15 60.09-26.07 89.67-39.8 40.92-19 84.73-46 130.83-49.67 36.26-2.85 70.9 9.42 98.6 31.56 31.77 25.39 62.32 62 103.63 73 40.44 10.79 81.35-6.69 119.13-24.28s75.16-39 116.92-43.05c59.73-5.85 113.28 22.88 168.9 38.84 30.2 8.66 59 6.17 87.09-7.5 22.43-10.89 48-26.93 60.65-49.24V0z"
            opacity=".5"
            fill="#fff"
          ></path>
          <path
            d="M0 0v5.63C149.93 59 314.09 71.32 475.83 42.57c43-7.64 84.23-20.12 127.61-26.46 59-8.63 112.48 12.24 165.56 35.4C827.93 77.22 886 95.24 951.2 90c86.53-7 172.46-45.71 248.8-84.81V0z"
            fill="#fff"
          ></path>
        </Svg>
        <Branding>
          <Primary>{title}</Primary>

          {subtitles &&
            <Secondary dangerouslySetInnerHTML={createHTML(subtitles)} />
          }

          {promoCodeText &&
            <PromoCodeText dangerouslySetInnerHTML={createHTML(promoCodeText)} />
          }

          {promoCode && (
            <PromoCode onClick={copyToClipboard(promoCode)}>
              {promoCode}
            </PromoCode>
          )}

          <Cta href="https://app.skipthedepot.com/register">Sign Up Now</Cta>
          <CtaScroll href={ctaScroll}>Keep reading for more info</CtaScroll>
        </Branding>
      </Container>
    </>
  )
}

export default Splash
