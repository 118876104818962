import React from "react"
import { graphql } from "gatsby"
import Layout from "../components/Layout"
import Splash from "../components/Splash"
import Cta from "../components/Cta"
import Ready from "../components/Ready"
import DetailedSteps from "../components/DetailedSteps"
import FundraisingShort from "../components/FundraisingShort"

export const PromotionPostTemplate = ({
  title,
  subtitles,
  promoCode,
  promoCodeText,
  detailedSteps,
  fundraisingShort
}) => (
  <>
    <Splash
      title={title}
      subtitles={subtitles}
      promoCode={promoCode}
      promoCodeText={promoCodeText}
      ctaScroll="#detailed-steps"
    />
    <DetailedSteps data={detailedSteps} id="detailed-steps" />
    <FundraisingShort data={fundraisingShort} />
    <Ready svg>
      <Cta href="https://app.skipthedepot.com/register">Get Started</Cta>
      <Cta to="/contact" variant>
        Contact us
      </Cta>
    </Ready>
  </>
)

const PromotionPost = ({
  data: {
    markdownRemark: {
      frontmatter: {
        tag,
        title,
        subtitles,
        promoCode,
        promoCodeText,
        seoDesc,
        detailedSteps,
        fundraisingShort,
      },
    },
  },
}) => {
  return (
    <Layout
      title={tag}
      seoDesc={seoDesc}
    >
      <PromotionPostTemplate
        title={title}
        subtitles={subtitles}
        promoCode={promoCode}
        promoCodeText={promoCodeText}
        detailedSteps={detailedSteps}
        fundraisingShort={fundraisingShort}
      />
    </Layout>
  )
}

export default PromotionPost

export const PageQuery = graphql`
  query promotionQuery {
    markdownRemark(frontmatter: { templateKey: { eq: "promotion-post" } }) {
      frontmatter {
        tag
        title
        subtitles
        promoCode
        promoCodeText
        seoDesc
        detailedSteps {
          title
          subtitle
          data {
            title
            body
          }
          video
        }
        fundraisingShort {
          title
          loc {
            city
            area
          }
        }
      }
    }
  }
`
